<template>
  <div class="input" :style="`width: ${width && width.length ? width : '100%'}`">
    <p class="input__name" v-if="field_name">{{ field_name }} <span style="color: red" v-if="required">*</span></p>

    <p class="error" v-if="error">{{ error }}</p>

    <div :class="['input__textinput', disabled ? 'disabled' : '']">
      
      <select @change="$emit('update:modelValue', $event.target.value)" 
              class="inside_input" 
              :disabled="disabled"
              :value="modelValue"
              >
        <option default selected disabled value="">{{ default_text }}</option>
        <option v-for="(elem, index) in default_elements" :key="index" :value="return_key ? elem[return_key] : elem">
          {{ display_key ? elem[display_key] : elem }}
        </option>
      </select>

    </div>
    <small class="hint">{{ hint }}</small>
  </div>
</template>

<script>
export default {
  name: 'select-input',

  // examples
  /*

  * model  - 'test text', default_elements = ['text1', 'text2']
  <select-input v-model="information.goal"
                field_name="Цель обработки персональных данных"
                :default_elements="elements"
                :disabled="is_fields_disabled"
                width="70%"></select-input>

  * model - '1', default_elements = [{'id': 5, 'name': 'display name'}]
  <select-input v-model="information.goal"
                field_name="Цель обработки персональных данных"
                :default_elements="elements"
                return_key="id" 
                display_key="name"
                :disabled="is_fields_disabled"
                width="70%"></select-input>
  */

  props: {
    "modelValue": {
      default: '',
      type: String,
    },
    "default_elements": Array,
    "field_name": String,
    "width": String,
    "placeholder": String,
    'hint': String,
    'required': Boolean,
    'error': String,
    'disabled': Boolean,
    'return_key': String, // key that should get value from dict for return value
    'display_key': String, // key that should get value from dict for display
    'default_text': {
      type: String,
      default: "Выберите один из элементов:"
    },
  },

  methods: {
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>