import { createStore } from 'vuex'
import axios from 'axios'
import router from './router'

function getServerUrl() {
  if (process.env.VUE_APP_SERVER_URL) {
    return process.env.VUE_APP_SERVER_URL
  } else {
    return process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/v1/' : 'https://api.privacy-check.ru/api/v1/'
  }
}

function getSiteUrl() {
  if (process.env.VUE_APP_SITE_URL) {
    return process.env.VUE_APP_SITE_URL
  } else {
    return process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080/' : 'https://privacy-check.ru/'
  }
}

export default createStore({
  restrict: false,

  state() {
    return {
      server: getServerUrl(),
      link: getSiteUrl(),
      auth_headers: { 'Authorization': 'JWT ' + localStorage.getItem('access_token') },
      cdn_script: '<script src="https://cdn.privacy-check.ru/cdn.js"></script>', //'<script src="https://cdn.privacy-check.ru/cdn.js"></script>',

      user: {},
      show_add_site: false,

      show_notification: false,

      site: {},
      scanning_is_running: false,

      toasts: [],

      company: {},
      information: {},
      third_parties: [],
      chosen_site_id: null,
    }
  },

  actions: {
    getAuthHeaders() {
      return 'test'
    },

    startSitesChecking({commit, state, dispatch}) {
      if (!state.scanning_is_running) {
        state.scanning_is_running = true
        let timeId = setTimeout(function tick() {
          dispatch('sendCheckRequest')
          timeId - setTimeout(tick, 10000)
        })
      }

    },

    sendCheckRequest({ commit, state, dispatch }) {
      axios.get(`${state.server}sites/check_user_sites/`,
        {headers: state.auth_headers})
        .then((response) => {
          if (response.data.ready) {
            commit('OPEN_NOTIFICATION')
            dispatch('getSites')

            let id = router.currentRoute.value.params?.id
            if (id) {
              dispatch('getCompany', id)
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            router.push('/login')
          }
        })
    },

    getSites({ commit, state, dispatch }, company_id) {
      axios.get(`${state.server}sites/${company_id ? company_id + '/' : ''}`,
        {headers: state.auth_headers})
        .then((response) => {
          commit("ASSIGN_SITES", response.data)

          for (let i=0; i<response.data.length; i++) {
            state.repeat_site_url = response.data[i].url
            if (response.data[i].site_type === 'in_progress') {
              dispatch("startSitesChecking")
            }
          }

          if (!response.data.length) {
            state.show_add_site = true
          }
        })
    },

    getCompany({ commit, state, dispatch }, id) {
      axios.get(`${state.server}company/${id}/`,
        {headers: state.auth_headers})
        .then((response) => {
          state.company = response.data
          state.information = response.data.information
          state.site = response.data.site
          if (state.site.site_type === 'in_progress') {
            dispatch('startSitesChecking')
          }
          state.third_parties = response.data.third_parties
        })
    }
  },

  mutations: {
    setAuthHeaders(state, access) {
      localStorage.setItem('access_token', access)
      state.auth_headers = { 'Authorization': 'JWT ' + access }
    },

    ASSIGN_SITES(state, data) {
      state.sites = data
    },

    ASSIGN_SITE(state,data) {
      state.site = data
    },

    ADD_NEW_SITE(state, data) {
      state.sites.unshift(data)
    },

    ASSIGN_THIRD_PARTIES(state, data) {
      state.third_parties = data
    },

    ASSIGN_INFORMATION(state, data) {
      state.information = Object.assign(state.information, data)
    },

    CHANGE_SITE_PAYMENT_STATUS(state) {
      state.site.is_paid = true
    },


    ASSIGN_THIRD_PARTY_BY_INDEX(state, args) {
      Object.keys(args.obj).forEach((key) => {
        state.third_parties[args.index][key] = args.obj[key]
      })
    },

    ASSIGN_CHOSEN_SITE_ID(state, data) {
      state.chosen_site_id = data
    },

    CHANGE_SHOW_ADD_SITE(state, value) {
      state.show_add_site = value
    },

    SET_FOURTH_STEP_CLICKED(state) {
      if (state.site) {
        state.site.is_to_fourth_step_clicked = true
      }
    },

    getUser(state) {
      axios.get(`${state.server}user/`,
      {headers: state.auth_headers})
      .then((response) => {
        state.user = response.data
      })
    },

    // NOTIFICATIONS AFTER ADDING NEW SITE
    OPEN_NOTIFICATION(state) {
      state.show_notification = true
      this.commit('CLOSE_NOTIFICATION')
    },
  
    CLOSE_NOTIFICATION(state) {
      setTimeout(() => {
        state.show_notification = false;
      }, 5000)
    },


    DANGER_TOAST(state, message) {
      state.toasts.unshift({
        'message': message,
        'status': 'error'
      })

      setTimeout(() => {
        state.toasts.pop()
      }, 5000)
    },

    SUCCESS_TOAST(state, message) {
      state.toasts.unshift({
        'message': message,
        'status': 'success'
      })

      setTimeout(() => {
        state.toasts.pop()
      }, 5000)
    },

    routeLink(state, link) {
      router.push(link)
    }
  },
  
  getters: {
    is_info_filled(state, getters) {
      let required = []
      if (!state.information.company_type) {
        return false
      }

      if (state.information.company_type === 'legal') {
        required = ['company_type', 'inn', 'ogrn', 'full_name', 'short_name', 'responsible_fio', 'address', 'email']
      } else if (state.information.company_type === 'personal') {
        required = ['company_type', 'full_name', 'locality', 'address', 'email']
      } else if (state.information.company_type === 'ip') {
        required = ['company_type', 'inn', 'ogrn', 'full_name', 'responsible_fio', 'address', 'email']
      }
      return required.every((field) => state.information[field])
    },

    is_forms_filled(state, getters) {
      if (state.site.forms) {
        for (let i=0; i<state.site.forms.length; i++) {
          if (!state.site.forms[i].status) {
            return false
          }
        }
        return true
      }
      return false
    },

    is_steps_ready(state, getters) {
      return getters.is_info_filled && getters.is_forms_filled
    }
  }
})
