import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import axios from 'axios'


const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/cdn',
    //   component: () => import("@/components/cdn/cdn.vue")
    // },
    {
      path: '/',
      redirect: '/sites',
    },
    {
      path: '/login',
      component: () => import("@/components/account/Login.vue")
    },
    {
      path: '/account',
      component: () => import("@/components/account/Account.vue"),
      name: 'account',
    },
    {
      path: '/change_password',
      component: () => import("@/components/account/ChangePassword.vue")
    },
    {
      path: '/restore_password',
      component: () => import("@/components/account/RestorePassword.vue")
    },
    {
      path: '/restore_password_sent',
      component: () => import("@/components/account/RestorePasswordSent.vue")
    },
    {
      path: '/pricing',
      component: () => import("@/components/tariffs/Tariffs.vue"),
      name: 'pricing',
    },
    {
      path: '/policy/:id/',
      component: () => import("@/components/dashboard/components/sections/DocumentPage")
    },
    {
      path: '/sites',
      component: () => import("@/components/dashboard/HomeWrapper.vue"),
      children: [
        { 
          path: '',
          component: () => import("@/components/dashboard/components/Sites.vue"),
          name: 'sites',
        },
        {
          path: ':id',
          component: () => import("@/components/dashboard/components/DashboardWrapper.vue"),
          children: [
            {
              path: '',
              redirect: to => {
                return { path: `/sites/${to.params.id}/company` }
              }
            },
            {
              path: 'company',
              component: () => import("@/components/dashboard/components/sections/forms/Information.vue"),
              name: 'company'
            },
            {
              path: 'website',
              component: () => import("@/components/dashboard/components/sections/forms/SiteForms.vue"),
              name: 'website'
            },
            {
              path: 'third_parties',
              component: () => import("@/components/dashboard/components/sections/forms/ThirdParties.vue"),
              name: 'third_parties'
            },
            {
              path: 'policy',
              component: () => import("@/components/dashboard/components/sections/DetailDocument.vue"),
              name: 'policy'
            },
            {
              path: 'instruction',
              component: () => import("@/components/dashboard/components/sections/Instruction.vue"),
              name: 'instruction'
            }
          ]
        },
      ],

      beforeEnter(to, from, next) {
        if (!localStorage.getItem('access_token') || !localStorage.getItem('refresh_token'))  {
          return next('/login')
        }
        axios.get(`${store.state.server}sites/check_user/`,
          { headers: store.state.auth_headers })
          .then((response) => {
            return next()
          })
          .catch((error) => {
            return next('/login')
          })
      },
    },
    {
      path: '/logout',
      component: () => import('@/components/account/Logout.vue')
    },
    {
      path: '/404',
      component: () => import('@/components/global/404.vue'),
    },
    {
      path: "/:catchAll(.*)",
      redirect: '/404'
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router