<template>
  <div class="input" :style="`width: ${width && width.length ? width : '100%'}; position: relative;`">
    <div v-if="show_tooltip && tooltip_text" class="input_tooltip">{{tooltip_text}}</div>
    <p class="input__name" v-if="field_name">{{ field_name }} <img src="@/assets/img/question-circle.svg" v-if="tooltip_text" @mouseover="show_tooltip = true" @mouseleave="show_tooltip = false"><span style="color: red" v-if="required">*</span></p>

    <div :class="['input__textinput', disabled ? 'disabled' : '', error ? 'error_input' : '']" >
      <img v-if="icon" :src="icon">
      <input :type="type ? type : 'text'"
             :value="modelValue"
             :class="['inside_input']"
             :disabled='disabled'
             @input="$emit('update:modelValue', $event.target.value)" 
             :required="required"
             :placeholder="placeholder"
             :style="`width: ${loading ? '70%' : '' }`" />

        <span v-if="loading" class="spinner small"></span>

    </div>
    <small class="hint">{{ hint }}</small>
    <p class="error" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'text-input',

  props: {
    "modelValue": String,
    "field_name": String,
    "width": String,
    "placeholder": String,
    'icon': String,
    'hint': String,
    'required': Boolean,
    'error': String,
    'type': String,
    'disabled': Boolean,
    'loading': Boolean,
    'tooltip_text': String,
  },

  data() {
    return {
      show_tooltip: false
    }
  },

  methods: {
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
.input_tooltip
  background-color: white
  position: absolute
  left: 40px
  top: -50px
  border: 1px solid black
  border-radius: 5px
  padding: 5px
  z-index: 100
  font-size: 13px
  width: 250px
</style>