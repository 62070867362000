<template>
  <div class="input" :style="`width: ${width || '100%'}`">
    <p class="input__name">{{ field_name }}</p>    

    <div class="fold_text_input" v-click-outside="closeData">
      <div class="add_input">
        <form @submit.prevent="addElement()">
          <div class="data_wrapper">
            <div :class="['data_el']" 
                 v-for="(elem, index) in values" 
                 :key="elem.id" 
                 >
              {{ elem }}
              <img src="@/assets/img/close.svg" @click="deleteElementOrOpenModal(index)"  />
            </div>
            <input v-model="new_element_name" 
                   @focus="show_fold_data = true" 
                   class="placeholder"
                   :placeholder="placeholder" />
          </div>
        </form>
      </div>

      <div class="fold" v-if="show_fold_data">
        <div class="new_field_wrapper">
          <div class="data_wrapper">
            <span style="margin-right: 15px;">
              {{ new_element_name.length ? 'Создать' : 'Начните набирать текст' }}
            </span>
            <div class="data_el binded yellow" v-if="new_element_name.length" @click="addElement()">{{ new_element_name }}</div>
          </div>
          <div class="press_btn">
            <p>{{ 'Нажмите' }}</p>
            <img src="@/assets/img/icon-keyboard-enter.svg"  />
          </div>
        </div>
      </div>

    </div>
  </div>

  <modal-window title="Удаление"
                description="Вы уверены, что хотите удалить данный объект?"
                button_name="Удалить"
                :show="show_delete_element"
                @close="show_delete_element = false"
                @submit="sendDeleteRequest()"></modal-window>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: "tag-input",

  /*
  * EXAMPLES

  1. common text field: modelValue = string
  <tag-input v-model="new_form.data"
            field_name="Наименование полей ввода персональных данных" />

  2. modelValue is array and stored in vuex store
  <tag-input v-model="third_parties" 
             :store="true"
             @update="ASSIGN_THIRD_PARTIES($event)"
             field_name="Наименование третьего лица"
             width="70%"
             api_url="third_party"
             :post_data="{'company': $route.params.id}"></tag-input>
  
  */

  props: [
    "modelValue", // array which we will change and return
    "store", // it means we can't update modelValue cuz this variable stored in vuex store and we must send $emit('update', new_value)
    "model_param", // if elements in model is Object and need get access like model[0][model_param]
    "field_name",
    "placeholder",
    "width",
    "api_url",
    "post_data", // this object will be send with post request to create object. name will be passed in this dict
  ],

  emits: ['update'],

  data() {
    return {
      show_fold_data: false,

      new_element_name: '',

      show_delete_element: false,
      delete_element_index: 0
    }
  },

  computed: {
    ...mapState(['server', 'auth_headers']),

    values() {
      if (typeof(this.modelValue) === 'string') {
        return this.modelValue.split(/\s*;\s*/).filter((elem) => elem.length)
      }
      if (typeof(this.modelValue) === 'object') {
        return this.modelValue.map((elem) => elem.name)
      }
    }
  },

  methods: {

    addElement() {
      if (this.new_element_name.length) {
        
        if (typeof(this.modelValue) === 'string') {
          let new_model_string = this.modelValue.slice()
          this.updateModel(new_model_string += `${this.new_element_name}; `)
        }

        if (typeof(this.modelValue) === 'object') {
          this.post_data.name = this.new_element_name
          axios.post(`${this.server}${this.api_url}/`,
            this.post_data,
            {headers: this.auth_headers})
            .then((response) => {
              let new_data = [...this.modelValue, response.data]

              if (this.store) {
                this.$emit('update', [...this.modelValue, response.data])
              } else {
                this.updateModel([...this.modelValue, response.data])
              }

            })
        }
        this.new_element_name = ''
      }
    },

    deleteElementOrOpenModal(index) {
      if (typeof(this.modelValue) === 'string') {
        let new_data = this.values.slice()
        new_data.splice(index, 1)
        this.updateModel(new_data.join('; '))
      } else {
        this.show_delete_element = true
        this.delete_element_index = index
      }

    },

    sendDeleteRequest() {
      if (typeof(this.modelValue) === 'object') {
        axios.delete(`${this.server}${this.api_url}/${this.modelValue[this.delete_element_index].id}/`,
          {headers: this.auth_headers})
          .then((response) => {
            let new_values = this.modelValue.slice()
            new_values.splice(this.delete_element_index, 1)
            this.show_delete_element = false
            this.$emit('update', new_values)
          })
      }

    },

    updateModel(new_data) {
      this.$emit('update:modelValue', new_data)
    },

    closeData() {
      this.show_fold_data = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/style.sass"
</style>