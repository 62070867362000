import axios from 'axios';
import { Service } from 'axios-middleware';
import router from './router';
import store from './store'

const service = new Service(axios);

service.register({
  onRequest(config) {
    return config;
  },

  onSync(promise) {
    return promise;
  },

  onResponseError(error) {
    // if no authorized try to refresh token
    if (error.response.status === 401) {
      // if we have error when we try to login skip it
      if (!error.response.config.url.includes('/api/v1/token/')) {
        if (localStorage.getItem('refresh_token')) {
          return axios.post(`${store.state.server}token/refresh/`,
            {refresh: localStorage.getItem('refresh_token')})
            .then((response) => {
              // save new token
              store.commit('setAuthHeaders', response.data.access)
              return make_request_again(error.response.config.url)
            })
            .catch((error) => {
              router.push('/login')
            })

        } else {
          router.push('/login')
        }
        
      }
    } else {
      if (error.response.data) {
        let re = error.response.data.match(/[а-яА-Я]/)
        if (re && re.input.length) {
          store.commit('DANGER_TOAST', error.response.data.replace('"', '').replace(']', '').replace('[', ''))
        } else {
          throw error
        }
      }
    }
  }
});

async function make_request_again(url) {
  return axios.get(url, 
    { headers: store.state.auth_headers })
    .then((response) => {
      return response
    })
    .catch((error) => {
      router.push('/login')
    })
}